<div class="page-wrapper compact-wrapper" id="canvas-bookmark">
  <!-- <app-header></app-header> -->
  <!-- Page Body Start-->
  <div class="page-body-wrapper">
    <div
      class="sidebar-wrapper"
      icon="stroke-svg"
      [class.close_icon]="navServices.collapseSidebar"
    >
      <app-sidebar></app-sidebar>
    </div>
    <div class="breadcrumb-header">
      <app-header [title]="headerTitle"></app-header>
    </div>
    <div class="page-body">
      <router-outlet></router-outlet>
      <!-- <footer class="footer">
        <app-footer></app-footer>
      </footer> -->
    </div>
  </div>
</div>
