import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Components
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

// Header Elements Components
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import { SearchComponent } from './components/header/elements/search/search.component';
// Directives
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
import { SortingDirective } from './directives/sorting.directive';

// Services
import { DecimalPipe } from '@angular/common';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { NgbdSortableHeaderDirective } from './directives/NgbdSortableHeader';
import { ChatService } from './services/chat.service';
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { TableService } from './services/table.service';

@NgModule({
  declarations: [
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    HeaderComponent,
    SortingDirective,
    ShowOptionsDirective,
    OnlyNumbersDirective,
    SearchComponent,
    MyAccountComponent,
    NgbdSortableHeaderDirective,
    SvgIconComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    NavService,
    ChatService,
    LayoutService,
    TableService,
    DecimalPipe
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    OnlyNumbersDirective,
    NgbdSortableHeaderDirective,
    SvgIconComponent,
    SortingDirective
  ]
})
export class SharedModule {}
