<div class="container-fluid">
  <div class="row">
    <div class="col-11">
    </div>
    <div class="col">
      <a routerLink="/chat">
        <img class="img-fluid for-light" width="50px" height="50px" src="assets/Milo-icon.png" alt="" />
      </a>
    </div>
  </div>
</div>