<div class="page-title">
  <div class="row align-items-center flex-row flex-nowrap">
    <div class="col">
      <h1>{{ title }}</h1>
    </div>
    <div class="col search-bar">
      <app-search></app-search>
    </div>
    <div class="col-auto">
      <div class="nav-menus d-flex align-items-center">
        <app-my-account></app-my-account>
      </div>
    </div>
  </div>
</div>
