import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, shareReplay, tap } from 'rxjs';
import { ApiPaths } from 'src/environments/enums/apiPaths.model';
import { environment } from 'src/environments/environment';
import { Person } from '../services/person.service';
import { AuthService } from './auth.service';
import { Company } from './company.service';

export interface Boat {
  id: number;
  name: string | null;
  flag: string | null;
  registrationPort: string | null;
  registrationNumber: string | null;
  imonumber: number | null;
  mmsinumber: number | null;
  boatType: string | null;
  registrationType: string | null;
  constructionYear: number | null;
  constructionYard: string | null;
  vhfcallSign: string | null;
  grossTonnage: number | null;
  netTonnage: number | null;
  loa: number | null;
  registeredLength: number | null;
  bim: number | null;
  draft: number | null;
  airDraft: number | null;
  people: Person[] | null;
  boatCompanies: any[];
  label: string;
}

export interface BoatRequest {
  id: number | null;
  name: string | null;
  flag: string | null;
  registrationPort: string | null;
  registrationNumber: string | null;
  imonumber: number | null;
  mmsinumber: number | null;
  boatType: string | null;
  registrationType: string | null;
  constructionYear: number | null;
  constructionYard: string | null;
  vhfcallSign: string | null;
  grossTonnage: number | null;
  netTonnage: number | null;
  loa: number | null;
  registeredLength: number | null;
  bim: number | null;
  draft: number | null;
  airDraft: number | null;
  idCompany?: number | null; //TODO to be removed
  people: Person[] | null;
  companies: Company[] | null;
}

@Injectable({
  providedIn: 'root'
})
export class BoatService {
  private boatUpdatedSource = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  boatUpdated$ = this.boatUpdatedSource.asObservable();

  private apiUrl = environment.apiUrl + ApiPaths.Boat;

  private boatsCache$: Observable<Boat[]> | null = null;
  private boatCache: Record<number, Observable<Boat>> = {};

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getBoatById(boatId: number): Observable<Boat> {
    if (!this.boatCache[boatId]) {
      const headers = this.authService.getHeadersWithAuthorization();
      this.boatCache[boatId] = this.http
        .get<Boat>(`${this.apiUrl}/${boatId}`, { headers })
        .pipe(shareReplay(1));
    }
    return this.boatCache[boatId];
  }

  getBoats(): Observable<Boat[]> {
    if (!this.boatsCache$) {
      const headers = this.authService.getHeadersWithAuthorization();
      this.boatsCache$ = this.http.get<Boat[]>(this.apiUrl, { headers }).pipe(
        map((boats) =>
          boats.sort((a, b) => {
            const nameA = a.name || '';
            const nameB = b.name || '';
            return nameA.localeCompare(nameB);
          })
        ),
        shareReplay(1)
      );
    }
    return this.boatsCache$;
  }

  createBoat(boatRequest: BoatRequest): Observable<Boat> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');
    return this.http.post<Boat>(this.apiUrl, boatRequest, { headers }).pipe(
      tap(() => {
        this.boatsCache$ = null;
      })
    );
  }

  updateBoat(boatRequest: BoatRequest): Observable<Boat> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');
    return this.http.put<Boat>(this.apiUrl, boatRequest, { headers }).pipe(
      tap(() => {
        this.boatsCache$ = null;
        this.boatCache[boatRequest.id] = null;
        this.boatUpdatedSource.next();
      })
    );
  }

  createBoatCompanyRelationship(
    idBoat: number,
    idCompany: number
  ): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('accept', 'text/plain');
    return this.http
      .put<any>(
        `${this.apiUrl}/RelationshipCompanyBoat?idBoat=${idBoat}&idCompany=${idCompany}`,
        null,
        { headers: headers }
      )
      .pipe(
        tap(() => {
          this.boatsCache$ = null;
        })
      );
  }

  removeBoatPersonRelationship(
    idBoat: number,
    idCompany: number
  ): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('accept', 'text/plain');
    return this.http
      .put<any>(
        `${this.apiUrl}/RemoveRelationshipCompanyBoat?idBoat=${idBoat}&idCompany=${idCompany}`,
        null,
        { headers: headers }
      )
      .pipe(
        tap(() => {
          this.boatsCache$ = null;
        })
      );
  }
}
