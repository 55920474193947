import { Component, HostListener, OnInit } from '@angular/core';
import { NavService } from 'src/app/shared/services/nav.service';
import { KaiDataInitializer } from '../../../components/general-pages/kai-general/kai-data.initializer';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  headerTitle = '';

  private touchStartX = 0;
  private touchEndX = 0;
  private screenWidth: number = window.innerWidth;

  constructor(
    public navServices: NavService,
    private headerService: HeaderService,
    private kaiDataInitializer: KaiDataInitializer
  ) {}

  ngOnInit(): void {
    this.headerService.title$.subscribe((title) => (this.headerTitle = title));

    this.kaiDataInitializer.init().catch((error) => {
      console.error('Failed to initialize Kai data:', error);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    if (this.screenWidth < 768) {
      this.touchStartX = event.touches[0].clientX;
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    if (this.screenWidth < 768) {
      this.touchEndX = event.changedTouches[0].clientX;
      this.handleSwipe();
    }
  }

  private handleSwipe() {
    const swipeDistance = this.touchStartX - this.touchEndX;
    const isSmallScreen = window.innerWidth < 768;

    if (isSmallScreen) {
      if (swipeDistance > 50) {
        this.navServices.collapseSidebar = true;
      } else if (swipeDistance < -50) {
        this.navServices.collapseSidebar = false;
      }
    }
  }
}
