import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService]
})
export class LoginComponent implements OnInit, OnDestroy {
  show = false;
  errorMessage: string | null = null;
  loginError = false;
  loggingIn = false;

  loginForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  private readonly authService = inject(AuthService);
  private readonly destroy$ = new Subject();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.authService.loggingIn
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.loggingIn = value));

    this.authService.loginError
      .pipe(takeUntil(this.destroy$))
      .subscribe((err) => (this.errorMessage = err));
  }

  login() {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;
      this.authService.login(username, password);
    }
  }

  showPassword() {
    this.show = !this.show;
  }

  ngOnDestroy() {
    this.destroy$.next({});
    this.destroy$.complete();
  }
}
