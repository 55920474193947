import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import sha256 from 'sha256';
import { ApiPaths } from 'src/environments/enums/apiPaths.model';
import { environment } from 'src/environments/environment';
import { User } from '../services/user.service';
import { UserAuthService } from './user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly AUTH_TOKEN_KEY = '';

  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);
  private readonly userService = inject(UserAuthService);

  private apiUrl = environment.apiUrl + ApiPaths.Authenticate;
  private userUrl = environment.apiUrl + ApiPaths.Users;

  private _currentUser$ = new BehaviorSubject<string>('');
  private _loginError$ = new BehaviorSubject<string>('');
  private _logginIn$ = new BehaviorSubject<boolean>(false);

  getAllUsersFiltered(): Observable<User[]> {
    const headers = this.getHeadersWithAuthorization();
    return this.http.get<User[]>(this.userUrl + '/GetAll', { headers }).pipe(
      map((users) => this.filterUsersByRole(users)),
      map((users) =>
        users.sort((a, b) => {
          const usernameA = a.username || '';
          const usernameB = b.username || '';
          return usernameA.localeCompare(usernameB);
        })
      )
    );
  }

  getAllUsersByIdAreaGeo(idArea: number): Observable<User[]> {
    const headers = this.getHeadersWithAuthorization();
    return this.http.get<User[]>(
      this.apiUrl + '/GetUsersByIdAreaGeo?idAreaGeo=' + idArea,
      { headers }
    );
  }

  filterUsersByRole(users: User[]): User[] {
    switch (this.userService.getUserRoleId()) {
      case 2:
        return users.filter((user) => user.idRuolos[0].id === 2);
      case 3:
        return users.filter(
          (user) =>
            [2].includes(user.idRuolos[0].id) ||
            user.id === this.userService.getUserId()
        );
      case 4:
        return users.filter(
          (user) =>
            [2, 3].includes(user.idRuolos[0].id) ||
            user.id === this.userService.getUserId()
        );
      case 5:
        return users.filter(
          (user) =>
            [2, 3, 4].includes(user.idRuolos[0].id) ||
            user.id === this.userService.getUserId()
        );
      case 6:
        return users.filter((user) => user.idRuolos?.[0].id === 6);
      case 7:
        return users.filter((user) => user.idRuolos?.[0].id === 7);
      case 12:
        return users.filter((user) =>
          [2, 3, 4, 12].includes(user.idRuolos[0].id)
        );
      case 13:
        return users.filter((user) => user.idRuolos[0].id === 13);
      default:
        return users;
    }
  }

  login(username: string, passwordUser: string): void {
    this._logginIn$.next(true);
    const password = sha256(passwordUser);
    const body = { username, password };
    const headers = new HttpHeaders({
      Accept: '*/*',
      'Content-Type': 'application/json'
    });
    this.http.post<AuthResponse>(this.apiUrl, body, { headers }).subscribe({
      next: (response) => {
        this.userService.setUserId(response.id);
        this.userService.setUserRoleId(response.ruolo[0].id);
        this.userService.setUserUsername(response.username);
        this.userService.setUserGeographicArea(response.geographicArea[0].id);
        this.setCurrentUser(username);
        localStorage.setItem(this.AUTH_TOKEN_KEY, response.token);
        this.router.navigate(['/home']);
        this._logginIn$.next(false);
      },
      error: (err: HttpErrorResponse) => {
        this.loginError = err.error.message ?? err.message;
        this._logginIn$.next(false);
      }
    });
  }

  logout(): void {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    this.userService.clearUserData();
    this.router.navigate(['/auth/login']);
    this.setCurrentUser('');
  }

  get loggingIn(): Observable<boolean> {
    return this._logginIn$;
  }

  // private set loggigIn(value: boolean) {
  //   this._logginIn$.next(value);
  // }

  get loginError(): Observable<string> {
    return this._loginError$;
  }

  private set loginError(error: string) {
    this._loginError$.next(error);
  }

  getAuthToken(): string | null {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  getCurrentUser(): Observable<string | null> {
    return this._currentUser$;
  }

  setCurrentUser(username: string) {
    this._currentUser$.next(username);
  }

  getHeadersWithAuthorization(): HttpHeaders {
    return new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.getAuthToken()}`
    );
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem(this.AUTH_TOKEN_KEY);
    return !!token;
  }
}

interface AuthResponse {
  id: number;
  firstName: string;
  lastName: string;
  token: string;
  username: string;
  ruolo: any[];
  geographicArea: any[];
}
