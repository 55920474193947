import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from './shared/components/content/content.component';
import { AuthGuard } from './utils/guards/auth.guard';

const routes: Routes = [
  { path: 'auth/login', component: LoginComponent },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./components/yacht-pages/dashboard.module').then(
            (m) => m.DashboardModule
          )
      },
      {
        path: 'create-entity',
        loadChildren: () =>
          import('./components/create-entity/create-entity.module').then(
            (m) => m.CreateEntityModule
          )
      },
      {
        path: 'kai-modules',
        loadChildren: () =>
          import('./components/services-pages/services.module').then(
            (m) => m.KaiModule
          )
      },
      {
        path: 'gen',
        loadChildren: () =>
          import('./components/general-pages/general.module').then(
            (m) => m.GeneralModule
          )
      },
      {
        path: 'calendars',
        loadChildren: () =>
          import('./components/smart-calendars/smart-calendar.module').then(
            (m) => m.SmartCalendarModule
          )
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('./components/chat/chat.module').then((m) => m.ChatModule)
      },
      {
        path: 'cockpit',
        loadChildren: () =>
          import('./components/cockpit/cockpit.module').then(
            (m) => m.CockpitModule
          ),
        canActivate: [() => inject(AuthGuard).canActivateCockpit()]
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        useHash: true,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled'
      })
    ]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
