import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, tap } from 'rxjs';
import { ApiPaths } from 'src/environments/enums/apiPaths.model';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Boat } from './boat.service';

export interface Person {
  id: number;
  name: string | null;
  surname: string | null;
  gender: string | null;
  cf: string | null;
  birthDate: string | null;
  jobPosition: string | null;
  phoneNumber: string | null;
  email: string | null;
  status: boolean | null;
  ooodateStart: string | null;
  ooodateEnd: string | null;
  idBoat: number | null;
  idBoatNavigation: Boat | null;
  fullName?: string;
}

export interface PersonRequest {
  id: number;
  name: string | null;
  surname: string | null;
  gender: string | null;
  cf: string | null;
  birthDate: string | null;
  jobPosition: string | null;
  phoneNumber: string | null;
  email: string | null;
  status: boolean | null;
  ooodateStart: string | null;
  ooodateEnd: string | null;
  idBoat: number | null;
}

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private apiUrl = environment.apiUrl + ApiPaths.Person;

  private personUpdateSource = new Subject<void>();
  personUpdated$ = this.personUpdateSource.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getAllPersons(): Observable<Person[]> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain');

    return this.http.get<Person[]>(`${this.apiUrl}/GetAll`, { headers }).pipe(
      map((persons) => {
        return persons.sort((a, b) => {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return nameA.localeCompare(nameB);
        });
      })
    );
  }

  createPerson(personData: PersonRequest): Observable<Person> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json');
    return this.http.post<Person>(this.apiUrl, personData, { headers });
  }

  updatePerson(personData: PersonRequest): Observable<Person> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json');
    return this.http
      .put<Person>(this.apiUrl, personData, { headers })
      .pipe(tap(() => this.personUpdateSource.next()));
  }

  getPersonsByIdBoat(idBoat: number): Observable<Person[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.http.get<Person[]>(
      `${this.apiUrl}/GetAllByIdBoat?idBoat=${idBoat}`,
      { headers }
    );
  }

  getPersonById(idPerson: number): Observable<Person> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.http.get<Person>(`${this.apiUrl}/${idPerson}`, { headers });
  }
}
