import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, tap } from 'rxjs';
import { ApiPaths } from 'src/environments/enums/apiPaths.model';
import { environment } from 'src/environments/environment';
import { Boat } from '../services/boat.service';
import { AuthService } from './auth.service';

export interface Company {
  id: number;
  name: string | null;
  address: string | null;
  registrationCountry: string | null;
  vatNumber: string | null;
  boats: Boat[] | null;
  type?: string | null;
}

export interface CompanyRequest extends Company {}

export interface Company {
  id: number;
  name: string | null;
  address: string | null;
  registrationCountry: string | null;
  vatNumber: string | null;
  boats: Boat[] | null;
  type?: string | null;
}

export interface CompanyRequest extends Company {}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private apiUrl = environment.apiUrl + ApiPaths.Company;

  private companyUpdatedSource = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  companyUpdated$ = this.companyUpdatedSource.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  createCompany(companyData: CompanyRequest): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Content-Type', 'application/json');
    return this.http.post<any>(this.apiUrl, companyData, { headers });
  }

  editCompany(companyData: CompanyRequest): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json');
    return this.http.put<any>(this.apiUrl, companyData, { headers });
  }

  getCompanies(): Observable<Company[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.http.get<Company[]>(this.apiUrl, { headers }).pipe(
      map((companies) =>
        companies.sort((a, b) => {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return nameA.localeCompare(nameB);
        })
      )
    );
  }

  getCompanyById(companyId: number): Observable<Company> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.http.get<Company>(`${this.apiUrl}/${companyId}`, { headers });
  }

  updateCompany(companyData: CompanyRequest): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json');
    return this.http.put<any>(this.apiUrl, companyData, { headers }).pipe(
      tap(() => {
        this.companyUpdatedSource.next();
      })
    );
  }
}
