/**
 * User to centralize the different paths for the different call
 * Please update when you need.
 * To be used like:
 * apiUrl = environment.apiUrl;
 * let url = `${this.apiUrl}/${ApiPaths.Foo}/all`;
 */
export enum ApiPaths {
  Arrival = '/Arrival',
  AuditLog = '/AuditLog',
  Authenticate = '/Users/authenticate',
  Boat = '/Boat',
  Booking = '/Booking',
  CategoryService = '/CategoryService',
  Cockpit = '/Cockpit',
  Company = '/Company',
  ExtInvoice = '/ExtService',
  Floating = '/Floating',
  GeographicArea = '/AreaGeografica',
  Kai = '/Affair',
  Person = '/Person',
  Port = '/Port',
  Reminder = '/Reminder',
  Role = '/Ruolo',
  Service = '/Service',
  Storages3 = '/Storages3',
  Supplier = '/Supplier',
  Users = '/Users',
  UserOoO = '/UserOoO'
}
