import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  public config = {
    settings: {
      layout: 'compact-wrapper',
      layout_type: 'ltr',
      layout_version: 'light-only',
      icon: 'stroke-svg'
    },
    color: {
      primary_color: '#7366ff',
      secondary_color: '#f73164'
    }
  };

  constructor() {
    if (this.config.settings.layout_type == 'rtl')
      document
        .getElementsByTagName('html')[0]
        .setAttribute('dir', this.config.settings.layout_type);

    document.documentElement.style.setProperty(
      '--theme-deafult',
      localStorage.getItem('primary_color')
    );
    document.documentElement.style.setProperty(
      '--theme-secondary',
      localStorage.getItem('secondary_color')
    );
    const primary =
      localStorage.getItem('primary_color') ||
      this.config.color.secondary_color;
    const secondary =
      localStorage.getItem('secondary_color') ||
      this.config.color.secondary_color;
    this.config.color.primary_color = primary;
    this.config.color.secondary_color = secondary;
    localStorage.getItem('primary_color') || this.config.color.primary_color;
    localStorage.getItem('secondary_color') ||
      this.config.color.secondary_color;
  }

  resetColor() {
    document.documentElement.style.setProperty('--theme-deafult', '#7366ff');
    document.documentElement.style.setProperty('--theme-secondary', '#f73164');
    (document.getElementById('ColorPicker1') as HTMLInputElement).value =
      '#7366ff';
    (document.getElementById('ColorPicker2') as HTMLInputElement).value =
      '#f73164';
    localStorage.setItem('primary_color', '#7366ff');
    localStorage.setItem('secondary_color', ' #f73164');
    window.location.reload();
  }
}
