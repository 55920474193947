<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="login-main">
            <form
              class="theme-form"
              [formGroup]="loginForm"
              (keydown.enter)="login()"
            >
              <h4>Sign in to account</h4>
              <p>Enter your username & password to login</p>
              <div class="form-group">
                <label class="col-form-label" for="loginUsername"
                  >Username</label
                >
                <input
                  class="form-control"
                  type="text"
                  required=""
                  placeholder="Enter your username"
                  formControlName="username"
                  id="loginUsername"
                  autocomplete="username"
                />
                @if (
                  loginForm.controls.username.touched &&
                  loginForm.controls.username.errors?.required
                ) {
                  <div class="text text-danger mt-1">
                    <span>Username is required</span>
                  </div>
                }
              </div>
              <div class="form-group">
                <label class="col-form-label" for="loginPassword"
                  >Password</label
                >
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  required=""
                  placeholder="*********"
                  id="loginPassword"
                  autocomplete="current-password"
                />
                <a
                  type="button"
                  class="show-hide"
                  (click)="showPassword()"
                  onkeypress="showPassword()"
                  role="button"
                  tabindex="0"
                >
                  @if (!show) {
                    <i aria-label="show" class="fa fa-eye-slash"></i>
                  } @else {
                    <i aria-label="hide" class="fa fa-eye"></i>
                  }
                </a>
                @if (
                  loginForm.controls.password.touched &&
                  loginForm.controls.password.errors?.required
                ) {
                  <div class="text text-danger mt-1">Password is required</div>
                }
              </div>
              <div class="form-group mb-0">
                @if (!!errorMessage) {
                  <div class="text text-danger mt-1">
                    {{ errorMessage }}
                  </div>
                }
                <div class="text-end mt-3">
                  <button
                    class="btn btn-login d-block w-100"
                    [disabled]="!loginForm.valid || loggingIn"
                    (click)="login()"
                    type="button"
                  >
                    @if (!loggingIn) {
                      <span>Sign in</span>
                    } @else {
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
