<!-- Page Sidebar Start-->
<div
  class="logo-wrapper"
  (touchstart)="onTouchStart($event)"
  (touchend)="onTouchEnd($event)"
>
  <a routerLink="/home">
    <img
      class="img-fluid for-light"
      src="assets/images/logo/kairos-logo2.png"
      alt="Logo"
      style="width: 4.375rem; height: auto"
    />
  </a>
  <button class="toggle-sidebar btn" (click)="sidebarToggle()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      class="status_toggle middle sidebar-toggle"
    >
      <rect
        x="3"
        y="3"
        width="7"
        height="7"
        stroke="black"
        stroke-width="2"
        fill="none"
      ></rect>
      <rect
        x="14"
        y="3"
        width="7"
        height="7"
        stroke="black"
        stroke-width="2"
        fill="none"
      ></rect>
      <rect
        x="14"
        y="14"
        width="7"
        height="7"
        stroke="black"
        stroke-width="2"
        fill="none"
      ></rect>
      <rect
        x="3"
        y="14"
        width="7"
        height="7"
        stroke="black"
        stroke-width="2"
        fill="none"
      ></rect>
    </svg>
  </button>
</div>

<nav class="sidebar-main">
  <div id="sidebar-menu">
    <ul class="sidebar-links">
      <div class="simplebar-content-wrapper">
        @for (menuItem of menuItems; track $index) {
          <li class="sidebar-list" [ngClass]="{ active: menuItem.active }">
            @if (menuItem.type === 'sub') {
              <a
                role="button"
                href="#"
                tabindex="0"
                class="sidebar-link sidebar-title"
                [ngClass]="{
                  active: menuItem.active,
                  'link-nav': !menuItem.children
                }"
                (click)="toggletNavActive(menuItem)"
                role="button"
              >
                <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
                <span>{{ menuItem.title }}</span>
                @if (menuItem.children) {
                  <div class="according-menu">
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-angle-down': menuItem.active,
                        'fa-angle-right': !menuItem.active,
                        'pull-right': true
                      }"
                    ></i>
                  </div>
                }
              </a>
              <!-- 2nd Level Menu -->
              @if (menuItem.children) {
                <ul
                  class="sidebar-submenu"
                  [ngClass]="{ active: menuItem.active }"
                  [ngClass]="{
                    'menu-open': menuItem.active,
                    'menu-close': !menuItem.active
                  }"
                  [style.display]="menuItem.active ? 'block' : 'none'"
                >
                  @for (childrenItem of menuItem.children; track $index) {
                    <li [ngClass]="{ active: childrenItem.active }">
                      <a
                        class="submenu-title"
                        [routerLink]="[childrenItem.path]"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        {{ childrenItem.title }}
                      </a>
                    </li>
                  }
                </ul>
              }
            } @else if (menuItem.type === 'link') {
              <a
                [routerLink]="!menuItem.type ? null : [menuItem.path]"
                routerLinkActive="active"
                class="sidebar-link sidebar-title"
                [ngClass]="{
                  active: menuItem.active,
                  'link-nav': !menuItem.children
                }"
              >
                <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
                <span>{{ menuItem.title }}</span>
              </a>
            }
          </li>
        }
      </div>
    </ul>
  </div>
</nav>
