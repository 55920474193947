import { Injectable, inject } from '@angular/core';
import { Kai } from 'src/app/shared/services/kai.service';
import { KaiService } from '../../../shared/services/kai.service';

@Injectable({
  providedIn: 'root'
})
export class KaiDataInitializer {
  private readonly kaiService = inject(KaiService);

  init(): Promise<Kai[]> {
    return this.kaiService
      .getAllKaisByStatus([1, 2, 3, 4])
      .toPromise()
      .then((kais) => {
        return Promise.all(
          kais.map(async (kai) => {
            kai.progress = await this.kaiService.getKaiProgress(kai);
            return kai;
          })
        );
      });
  }
}
