import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private titleSource = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/member-ordering
  title$ = this.titleSource.asObservable();

  updateHeader(title: string) {
    setTimeout(() => {
      this.titleSource.next(title);
    });
  }
}
