import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  sortData<T>(data: T[], header: string, isAscending: boolean): T[] {
    return data.sort((a, b) => {
      let comparison = 0;
      const valueA = this.getValue(a, header);
      const valueB = this.getValue(b, header);

      if (valueA < valueB) {
        comparison = -1;
      } else if (valueA > valueB) {
        comparison = 1;
      }

      return isAscending ? comparison : -comparison;
    });
  }

  search<T>(data: T[], searchText: string, fields: string[]): T[] {
    const searchTextLowerCase = searchText.toLowerCase();
    return data.filter((item) =>
      fields.some((field) => {
        const value = this.getValue(item, field)?.toString().toLowerCase();
        return value?.includes(searchTextLowerCase);
      })
    );
  }

  private getValue<T>(item: T, field: string): any {
    return field.split('.').reduce((acc, part) => acc && acc[part], item);
  }
}
