import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import {
  Reminder,
  ReminderService
} from 'src/app/shared/services/reminder.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {
  currentUser: string | null = null;
  public userName: string;
  notificationCount = 0;
  reminders: Reminder[] = [];
  dropdownOpen = false;

  private pollingSubscription: Subscription;

  constructor(
    public readonly router: Router,
    private readonly authService: AuthService,
    private readonly userService: UserAuthService,
    private readonly reminderService: ReminderService
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.getUserUsername();
    this.getNotificationCount();
    this.updateCounter();
  }

  ngOnDestroy() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  logoutFunc() {
    this.router.navigateByUrl('auth/login');
  }

  logout() {
    this.authService.logout();
  }

  getEndOfDay(): Date {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
      999
    );
  }

  getNotificationCount() {
    if (this.currentUser) {
      this.reminderService
        .getRemindersByUserIdByStatus(this.userService.getUserId(), 0)
        .subscribe(
          (reminders) => {
            const endOfDay = this.getEndOfDay();
            this.reminders = reminders.filter(
              (reminder) => new Date(reminder.date) <= endOfDay
            );
            this.notificationCount = this.reminders.length;
          },
          (error) => {
            console.error('Errore nel recupero delle notifiche', error);
          }
        );
    }
  }

  updateCounter() {
    if (this.userService.getUserId()) {
      this.pollingSubscription = this.reminderService.startPollingNotifications(
        this.userService.getUserId(),
        (reminders: Reminder[]) => {
          const endOfDay = this.getEndOfDay();
          this.reminders = reminders.filter(
            (reminder) => new Date(reminder.date) <= endOfDay
          );
          this.notificationCount = this.reminders.length;
        }
      );
    }
  }

  updateReminderasDone(reminder: Reminder) {
    this.reminderService
      .editReminder({ ...reminder, status: 1 })
      .subscribe(() => {
        reminder.status = 1;
        this.getNotificationCount();
      });
  }
}
