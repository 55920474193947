import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  private readonly USER_ID_KEY = 'user_id';
  private readonly USER_USERNAME = 'user_username';
  private readonly USER_ROLES = 'user_role';
  private readonly USER_AREA = 'user_area';

  private readonly roles: Record<number, string> = {
    1: 'Dev',
    2: 'Runner',
    3: 'Agent',
    4: 'AreaManager',
    5: 'Director',
    6: 'Invoicing',
    7: 'Accounting',
    8: 'MD',
    9: 'Superuser',
    10: 'Supplier',
    11: 'Subagent',
    12: 'HR',
    13: 'Logistics',
    14: 'InvoicingManager'
  };

  private readonly allowedRoles = [
    'Dev',
    'MD',
    'Superuser',
    'Invoicing',
    'InvoicingManager',
    'Director'
  ];

  setUserId(userId: number): void {
    localStorage.setItem(this.USER_ID_KEY, userId.toString());
  }

  getUserId(): number {
    return parseInt(localStorage.getItem(this.USER_ID_KEY)!, 10);
  }

  setUserUsername(username: string): void {
    localStorage.setItem(this.USER_USERNAME, username);
  }

  getUserUsername(): string {
    return localStorage.getItem(this.USER_USERNAME)!;
  }

  setUserRoleId(roleId: number): void {
    localStorage.setItem(this.USER_ROLES, roleId.toString());
  }

  getUserRoleId(): number {
    return parseInt(localStorage.getItem(this.USER_ROLES)!, 10);
  }

  setUserGeographicArea(areaId: number): void {
    localStorage.setItem(this.USER_AREA, areaId.toString());
  }

  getUserGeographicArea(): number {
    return parseInt(localStorage.getItem(this.USER_AREA)!, 10);
  }

  clearUserData(): void {
    this.clearUserId();
    this.clearUserUsername();
  }

  isSuperUser(): boolean {
    const roleId = this.getUserRoleId();
    return roleId === 1 || roleId === 9; //dev and superuser
  }

  isManagingDirector(): boolean {
    const roleId = this.getUserRoleId();
    return roleId === 8; //MD
  }

  getCurrentUserRole(): string {
    const roleId = this.getUserRoleId();
    return this.roles[roleId];
  }

  getAllowedRoles(): string[] {
    return this.allowedRoles;
  }

  isAllowedUser(): boolean {
    const allowedRoles = this.getAllowedRoles();
    const currentUserRole = this.getCurrentUserRole();
    return allowedRoles.includes(currentUserRole);
  }

  private clearUserUsername(): void {
    localStorage.removeItem(this.USER_USERNAME);
  }

  private clearUserId(): void {
    localStorage.removeItem(this.USER_ID_KEY);
  }
}
