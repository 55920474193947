import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthGuard } from 'src/app/utils/guards/auth.guard';

// Menu
export interface Menu {
  headTitle1?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  active?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})
export class NavService {
  // Search Box
  public search = false;

  // Collapse Sidebar
  public collapseSidebar = false;

  // Getter per MENUITEMS che include "Cockpit" solo se l'utente ha i permessi
  get MENUITEMS(): Menu[] {
    const items: Menu[] = [
      { headTitle1: 'Main' },
      {
        path: '/home',
        title: 'Yacht home',
        icon: 'home',
        type: 'link',
        active: true
      },
      {
        title: 'Add Entity',
        icon: 'table',
        type: 'sub',
        active: false,
        children: [
          {
            title: 'Add Yacht',
            type: 'link',
            active: false,
            path: '/create-entity/yacht'
          },
          {
            title: 'Add Person',
            active: false,
            type: 'link',
            path: '/create-entity/person'
          },
          {
            title: 'Add Company',
            active: false,
            type: 'link',
            path: '/create-entity/company'
          },
          {
            title: 'Add Supplier',
            active: false,
            type: 'link',
            path: '/create-entity/supplier'
          }
        ]
      },
      { title: 'Kai', icon: 'task', type: 'link', path: '/gen/kai' },
      {
        title: 'Booking',
        icon: 'project',
        active: false,
        type: 'sub',
        children: [
          { title: 'List', type: 'link', path: '/gen/booking' },
          { title: 'Calendar', type: 'link', path: '/calendars/booking' }
        ]
      },
      {
        title: 'Arrival',
        icon: 'icons',
        active: false,
        type: 'sub',
        children: [
          { title: 'List', type: 'link', path: '/gen/arrival' },
          { title: 'Calendar', type: 'link', path: '/calendars/arrival' }
        ]
      },
      {
        title: 'Add Services',
        icon: 'file',
        type: 'link',
        path: '/kai-modules/services'
      },
      {
        title: 'Backoffice',
        icon: 'form',
        active: false,
        type: 'sub',
        children: [
          { title: 'Landing', type: 'link', path: '/gen/backoffice-landing' },
          { title: 'Specs', type: 'link', path: '/gen/backoffice' }
        ]
      },
      {
        title: 'Accounting',
        icon: 'bonus-kit',
        active: false,
        type: 'sub',
        children: [
          { title: 'Landing', type: 'link', path: '/gen/accounting-landing' },
          { title: 'Specs', type: 'link', path: '/gen/accounting' }
        ]
      },
      {
        path: '/calendars/smart-agenda',
        title: 'Smart agenda',
        icon: 'calendar',
        type: 'link'
      },
      { path: '/chat', title: 'MILO', icon: 'chat', type: 'link' },
      {
        path: '/chat/floating-page',
        title: 'Floating services',
        icon: 'board',
        type: 'link'
      }
    ];

    // Condizionale per inserire "Cockpit" prima di "About Us"
    if (this.authGuard.canActivateCockpit()) {
      items.push({
        path: '/cockpit',
        title: 'Cockpit',
        icon: 'charts',
        type: 'link'
      });
    }

    items.push({
      path: '/gen/bwa-map',
      title: 'About us',
      icon: 'social',
      type: 'link'
    });

    return items;
  }

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  constructor(private authGuard: AuthGuard) {
    this.items.next(this.MENUITEMS);
  }
}
