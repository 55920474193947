import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

const today = new Date().toLocaleString('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
});

interface UserReponse {
  workflow_status_code: number;
  message: string;
  response: Response;
}

export interface Messages {
  sender?: number; //1 per chat 0 per user
  time?: string;
  text?: string;
}

export interface Entity {
  NAME: string;
  TYPE: string;
  VALUE: string | null;
  GOODNESS: string;
}

interface Response {
  intent: string;
  entities: Entity[];
}

export interface WorkflowResponse {
  workflow_status_code: number;
  message: string;
  response: Response;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public chat: Messages[];

  apiGetStatus =
    'https://48qzqxxtm6.execute-api.eu-central-1.amazonaws.com/milos/create-status';
  apiResponse =
    'https://48qzqxxtm6.execute-api.eu-central-1.amazonaws.com/milos/user-request';
  queryRequest =
    'https://48qzqxxtm6.execute-api.eu-central-1.amazonaws.com/milos/query-request';

  private typingSubject = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  typing$ = this.typingSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  setTyping(typing: boolean) {
    this.typingSubject.next(typing);
  }

  getChatHistory(): Observable<Messages[] | undefined> {
    return of(this.chat);
  }

  getStatus(): Observable<any> {
    return this.httpClient.get<any>(this.apiGetStatus);
  }

  getQueryRequest(intent: string, entities: Entity[]): Observable<any> {
    return this.httpClient.post<any>(this.queryRequest, { intent, entities });
  }

  public sendMessageFromUser(message: string): Observable<any> {
    let req: Observable<UserReponse>;
    this.chat.push({
      sender: 0,
      time: today.toLowerCase(),
      text: message
    });
    setTimeout(function () {
      document
        .querySelector('.chat-history')
        ?.scrollBy({ top: 200, behavior: 'smooth' });
    }, 310);
    req = this.sendMessageToBot(message);
    return req;
  }

  public sendMessageToBot(message: string): Observable<UserReponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.httpClient.post<UserReponse>(
      this.apiResponse,
      { request: message },
      httpOptions
    );
  }

  sendMessageFromChatbot(chatText: string) {
    this.chat.push({
      sender: 1,
      time: today.toLowerCase(),
      text: chatText
    });

    setTimeout(function () {
      document
        .querySelector('.chat-history')
        ?.scrollBy({ top: 200, behavior: 'smooth' });
    }, 2310);
  }

  clearChatHistory() {
    this.chat = [];
  }
}
