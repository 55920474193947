<div class="media profile-media">
  <button
    class="btn px-0 notification-bell"
    (click)="dropdownOpen = !dropdownOpen"
    [attr.aria-haspopup]="true"
    [attr.aria-expanded]="dropdownOpen ? 'true' : 'false'"
  >
    <i class="fa fa-bell"></i>
    @if (notificationCount > 0) {
      <span class="badge">{{ notificationCount }}</span>
    }
  </button>
  <div class="dropdown-menu" [class.show]="dropdownOpen">
    @if (reminders.length) {
      <div
        *ngFor="let reminder of reminders"
        class="dropdown-item d-flex justify-content-between"
      >
        @if (reminder.idKai) {
          <a [routerLink]="['/home/yacht-kai', reminder.idKai]">{{
            reminder.name
          }}</a>
        } @else {
          <a
            [routerLink]="['calendars/smart-agenda']"
            [queryParams]="{ date: reminder.date }"
            >{{ reminder.name }}</a
          >
        }
        <button
          class="btn btn-link btn-sm ml-2"
          aria-label="Mark as done"
          (click)="updateReminderasDone(reminder)"
        >
          <i class="fa fa-check-square-o"></i>
        </button>
      </div>
    } @else {
      <div class="dropdown-item">No new reminders</div>
    }
  </div>
  <img
    class="b-r-10"
    src="assets/images/dashboard/user-logo2.png"
    alt=""
    style="width: 3rem; height: auto"
  />

  <div class="media-body d-none d-md-block">
    <div>{{ currentUser }}</div>
    <a href="#" (click)="logout()" role="button">Disconnect</a>
  </div>
</div>
