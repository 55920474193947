import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject, of, tap } from 'rxjs';
import { ApiPaths } from 'src/environments/enums/apiPaths.model';
import { environment } from 'src/environments/environment';
import { ReminderRequest } from '../services/reminder.service';
import { Arrival } from './arrival.service';
import { AuthService } from './auth.service';
import { ReminderService } from './reminder.service';
import { ServicesService } from './services.service';
import { UserService } from './user.service';

export interface Kai extends KaiRequest {
  progressDetails?: string[];
  agentName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idBoatNavigation?: any;
  arrivals?: Arrival[];
  geoGraphicAreas?: [];
  progress?: number;
  geographicAreas?: string[];
}

export interface KaiRequest {
  id: number;
  startDate: string | null;
  endDate: string | null;
  totalNet: number | null;
  totalVat: number | null;
  totalGross: number | null;
  deposit: number | null;
  totalDue: number | null;
  idBoat: number | null;
  area: number | null;
  number: string | null;
  portId: number | null;
  status: number | null;
  idUser: number;
  idPerson: number | null;
  charter: boolean | null;
  mockedArrival?: boolean | null;
}

export const KaiStatus = {
  1: 'Open',
  2: 'Request Invoice',
  3: 'Expiring',
  4: 'Expired',
  5: 'Closed',
  10: 'Deleted'
};

@Injectable({
  providedIn: 'root'
})
export class KaiService {
  private readonly http = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly servicesService = inject(ServicesService);
  private readonly datePipe = inject(DatePipe);
  private readonly reminderService = inject(ReminderService);

  private apiUrl = environment.apiUrl + ApiPaths.Kai;

  private kaiCreatedSource = new Subject<void>();
  private kaiUpdatedSource = new Subject<void>();

  // Cache persistente usando localStorage
  private readonly progressCacheKey = 'kaiProgressCache';
  private progressCache: Map<number, number>; // Cache per il progresso dei Kai
  private cache: Map<string, Kai[]> = new Map<string, Kai[]>(); // Cache per le chiamate API

  // eslint-disable-next-line @typescript-eslint/member-ordering
  kaiUpdated$ = this.kaiUpdatedSource.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  kaiCreated$ = this.kaiCreatedSource.asObservable();

  constructor() {
    this.loadCache();
  }

  getAllKais(): Observable<Kai[]> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain');
    return this.http.get<Kai[]>(this.apiUrl, { headers });
  }

  getAllKaisByStatus(status: number[], idBoat?: number): Observable<Kai[]> {
    const statusKaiQuery = status.join(',');
    let apiUrl = `${this.apiUrl}/GetAllByFilters?statusList=${statusKaiQuery}`;
    if (idBoat !== undefined) {
      apiUrl += `&idBoat=${idBoat}`;
    }

    if (this.cache.has(apiUrl)) {
      return of(this.cache.get(apiUrl) as Kai[]);
    }

    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain');
    return this.http
      .get<Kai[]>(apiUrl, { headers })
      .pipe(tap((kaiList) => this.cache.set(apiUrl, kaiList)));
  }

  getKaiById(kaiId: number): Observable<Kai> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'application/json');

    return this.http.get<Kai>(`${this.apiUrl}/${kaiId}`, { headers });
  }

  getKaisFromIdBoat(idBoat: number): Observable<Kai[]> {
    const url = `${this.apiUrl}/GetAffairFromIdBoat?idBoat=${idBoat}`;
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain');

    return this.http.get<Kai[]>(url, { headers });
  }

  createKai(kaiData: KaiRequest): Observable<{ id: number }> {
    const url = `${this.apiUrl}/InsertNewAffair`;
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain')
      .set('Content-Type', 'application/json');

    return this.http.post<{ id: number }>(url, kaiData, { headers }).pipe(
      tap(() => {
        this.kaiCreatedSource.next(); // Emit a notification when a kai is successfully created
      })
    );
  }

  /**
   * Kai progress
   * 75% is the avg of all the services
   * 20% is the avg of all the arrival status
   * 5% is kai status
   * @param kai
   * @returns
   */
  async getKaiProgress(kai: Kai): Promise<number> {
    // Controlla la cache prima di calcolare il progresso
    if (this.progressCache.has(kai.id)) {
      return this.progressCache.get(kai.id) as number;
    }

    let progress = 0;
    //Filter out deleted arrivals
    const arrivals = kai.arrivals?.filter((a) => a.status !== 10);
    if (arrivals?.length) {
      let arrivalsProgress = 0;
      for (let arr of arrivals) {
        arr.services = await new Promise((resolve) => {
          this.servicesService
            .getAllServicesByIdArrival(arr.id)
            //Filter out deleted and cancelled services
            .subscribe((services) =>
              resolve(services.filter((s) => ![4, 10].includes(s.status)))
            );
        });
        if (arr.services?.length) {
          let servicesProgress = 0;
          for (let ser of arr.services) {
            servicesProgress +=
              await this.servicesService.getServiceProgress(ser);
          }

          arrivalsProgress += (servicesProgress * 0.75) / arr.services.length;
        }
        switch (arr.status) {
          //Arrived
          case 2:
            arrivalsProgress += 10;
            break;
          //Departed
          case 3:
            arrivalsProgress += 20;
            break;
          default:
            break;
        }
      }

      progress += arrivalsProgress / arrivals?.length;
    }

    progress += [2, 5].includes(kai.status) ? 5 : 0;
    progress = Math.floor(progress);

    // Memorizza il progresso calcolato nella cache
    this.progressCache.set(kai.id, progress);
    this.saveCache(); // Salva la cache persistente

    return progress;
  }

  updateKai(kaiData: KaiRequest): Observable<Kai> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain')
      .set('Content-Type', 'application/json');
    return this.http.put<Kai>(this.apiUrl, kaiData, { headers });
  }

  requestInvoiceWithReminder(kaiData: KaiRequest): void {
    kaiData.status = 2;
    this.updateKai(kaiData).subscribe(
      () => {
        console.log('Invoice requested successfully');
        this.userService.getUsersByRoleIds([6, 14]).subscribe(
          (users) => {
            users.forEach((user) => {
              const request: ReminderRequest = {
                id: 0,
                name: 'REQUESTED INVOICE',
                description: kaiData.number,
                date: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm'),
                idKai: kaiData.id,
                idUtente: user.id,
                status: 0, // status: not done
                type: 0, // type: reminder
                idService: null,
                idBooking: null,
                idArrival: null
              };
              this.reminderService.createReminder(request).subscribe(
                () => {
                  console.log(`Reminder created successfully`);
                },
                () => {
                  console.log(`Error creating reminder`);
                }
              );
            });
          },
          (error) => {
            console.error('Error fetching users:', error);
          }
        );
      },
      (error) => {
        console.error('Error requesting invoice:', error);
      }
    );
  }

  private loadCache() {
    const cacheData = localStorage.getItem(this.progressCacheKey);
    if (cacheData) {
      this.progressCache = new Map<number, number>(JSON.parse(cacheData));
    } else {
      this.progressCache = new Map<number, number>();
    }
  }

  private saveCache() {
    localStorage.setItem(
      this.progressCacheKey,
      JSON.stringify(Array.from(this.progressCache.entries()))
    );
  }
}
