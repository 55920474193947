import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, interval, switchMap } from 'rxjs';
import { ApiPaths } from '../../../environments/enums/apiPaths.model';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

export interface Reminder {
  id: number;
  idKai: number | null;
  idArrival: number | null;
  idService: number | null;
  idBooking: number | null;
  type: number | null;
  name: string | null;
  description: string | null;
  idUtente: number | null;
  date: string | null;
  status: number | null;
}

export interface ReminderRequest extends Reminder {}

@Injectable({
  providedIn: 'root'
})
export class ReminderService {
  private apiUrl = environment.apiUrl + ApiPaths.Reminder;
  private pollingInterval = 300000;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}
  getRemindersByUserIdByStatus(
    idUtente: number,
    isDone: number
  ): Observable<Reminder[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    const url = `${this.apiUrl}/GetRemindersByFilter?idUtente=${idUtente}&status=${isDone}`;
    return this.httpClient.get<Reminder[]>(url, { headers });
  }

  getRemindersDoneByUserId(idUtente: number): Observable<Reminder[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    const url = `${this.apiUrl}/GetRemindersByFilter?idUtente=${idUtente}&status=1`;
    return this.httpClient.get<Reminder[]>(url, { headers });
  }

  createReminder(reminderData: ReminderRequest): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain')
      .set('Content-Type', 'application/json');
    return this.httpClient.post<any>(`${this.apiUrl}`, reminderData, {
      headers
    });
  }

  editReminder(reminderData: ReminderRequest): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain')
      .set('Content-Type', 'application/json');
    return this.httpClient.put<any>(`${this.apiUrl}`, reminderData, {
      headers
    });
  }

  startPollingNotifications(
    userId: number,
    callback: (reminders: Reminder[]) => void
  ): Subscription {
    return interval(this.pollingInterval)
      .pipe(switchMap(() => this.getRemindersByUserIdByStatus(userId, 0)))
      .subscribe((reminders: Reminder[]) => {
        callback(reminders);
      });
  }
}
