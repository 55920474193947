import { Component } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Boat, BoatService } from 'src/app/shared/services/boat.service';
import {
  Company,
  CompanyService
} from 'src/app/shared/services/company.service';
import { Kai, KaiService } from 'src/app/shared/services/kai.service';
import { Person, PersonService } from 'src/app/shared/services/person.service';
import {
  Supplier,
  SupplierService
} from 'src/app/shared/services/supplier.service';
import { Menu, NavService } from '../../../../services/nav.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  public menuItems: Menu[] = [];
  public items: Menu[] = [];

  public searchResult = false;
  public searchResultEmpty: boolean | null = null;
  public text = '';

  constructor(
    public navServices: NavService,
    private supplierService: SupplierService,
    private kaiService: KaiService,
    private boatService: BoatService,
    private personService: PersonService,
    private companyService: CompanyService
  ) {
    this.navServices.items.subscribe((menuItems) => (this.items = menuItems));
  }

  clearSearch() {
    this.text = '';
    this.navServices.search = false;
    this.searchResultEmpty = null;
    this.menuItems = [];
  }

  searchTerm(term: string) {
    if (!term) {
      this.removeFix();
      this.menuItems = [];
      return;
    }

    this.addFix();

    let items: Menu[] = [];
    term = term.toLowerCase();

    this.items.forEach((menuItems) => {
      if (
        menuItems.title
          ?.toLowerCase()
          .split(' ')
          .some((word) => word.startsWith(term)) &&
        menuItems.type === 'link'
      ) {
        items.push(menuItems);
      }

      menuItems.children?.forEach((subItems) => {
        if (
          subItems.title
            ?.toLowerCase()
            .split(' ')
            .some((word) => word.startsWith(term)) &&
          subItems.type === 'link'
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }

        subItems.children?.forEach((suSubItems) => {
          if (
            suSubItems.title
              ?.toLowerCase()
              .split(' ')
              .some((word) => word.startsWith(term))
          ) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
    });

    forkJoin({
      suppliers: this.supplierService.getAllSuppliers(),
      boats: this.boatService.getBoats(),
      persons: this.personService.getAllPersons(),
      kais: this.kaiService.getAllKais(),
      companies: this.companyService.getCompanies()
    }).subscribe(({ suppliers, boats, persons, kais, companies }) => {
      this.processEntities(
        term,
        items,
        suppliers,
        boats,
        persons,
        kais,
        companies
      );
      this.checkSearchResultEmpty(items);
      this.menuItems = items;
    });
  }

  checkSearchResultEmpty(items: Menu[]) {
    this.searchResultEmpty = items.length === 0;
  }

  addFix() {
    this.searchResult = true;
  }

  removeFix() {
    this.searchResult = false;
    this.text = '';
  }

  private processEntities(
    term: string,
    items: Menu[],
    suppliers: Supplier[],
    boats: Boat[],
    persons: Person[],
    kais: Kai[],
    companies: Company[]
  ) {
    suppliers.forEach((supplier) => {
      if (
        supplier.name
          .toLowerCase()
          .split(' ')
          .some((word) => word.startsWith(term))
      ) {
        items.push({
          title: `${supplier.name} - SUPPLIER`,
          path: `/create-entity/supplier/${supplier.id}`,
          icon: 'table',
          type: 'link'
        });
      }
    });

    persons.forEach((person) => {
      const fullName = `${person.name} ${person.surname}`.toLowerCase();
      if (fullName.split(' ').some((word) => word.startsWith(term))) {
        items.push({
          title: `${fullName} - ${person.jobPosition}`,
          path: `/create-entity/person/${person.id}`,
          icon: 'table',
          type: 'link'
        });
      }
    });

    companies.forEach((company) => {
      if (
        company.name
          ?.toLowerCase()
          .split(' ')
          .some((word) => word.startsWith(term))
      ) {
        items.push({
          title: `${company.name} - COMPANY`,
          path: `/create-entity/company/${company.id}`,
          icon: 'table',
          type: 'link'
        });
      }
    });

    boats.forEach((boat) => {
      if (
        boat.name
          ?.toLowerCase()
          .split(' ')
          .some((word) => word.startsWith(term))
      ) {
        items.push({
          title: `${boat.name} - ${boat.registeredLength} mt`,
          path: `/home/${boat.id}`,
          icon: 'icons',
          type: 'link'
        });
      }
    });

    kais.forEach((kai) => {
      if (
        kai.number
          ?.toLowerCase()
          .split(' ')
          .some((word) => word.startsWith(term))
      ) {
        items.push({
          title: `${kai.number} - KAI`,
          path: `/home/yacht-kai/${kai.id}`,
          icon: 'task',
          type: 'link'
        });
      }
    });
  }
}
