import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appSort]'
})
export class SortingDirective implements OnChanges {
  @Input() sortKey: string;
  @Input() selectedHeader: string;
  @Input() isAscending: boolean;
  //@Input() sortFunction: (key: string) => void;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('click') onClick() {
    // this.sortFunction(this.sortKey);
    this.updateArrow();
  }

  ngOnChanges() {
    this.updateArrow();
  }

  private updateArrow() {
    if (this.selectedHeader === this.sortKey) {
      const icon = this.el.nativeElement.querySelector('i');
      this.renderer.removeClass(icon, 'fa-caret-down');
      this.renderer.removeClass(icon, 'fa-caret-up');
      this.renderer.addClass(
        icon,
        this.isAscending ? 'fa-caret-down' : 'fa-caret-up'
      );
    }
  }
}
