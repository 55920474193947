import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';
import { ApiPaths } from '../../../environments/enums/apiPaths.model';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';

export interface User {
  id: number;
  username: string | null;
  name: string | null;
  surname: string | null;
  userSessions: UsersSession[] | null;
  idRuolos: IdRuolos[];
  status: boolean;
}

interface UsersSession {
  id: number;
  idUser: number;
  token: string;
  sessionExpired: string;
}

interface IdRuolos extends Role {
  idFunctonalities: number[];
  idUsers: number[];
}

export interface Role {
  id: number;
  description: string;
  name: string;
}

export interface NewUser {
  id: number;
  username: string;
  password: string;
  name: string;
  surname: string;
  idAreaGeograficas: { id: number; name: string }[];
  idRuolos: { id: number; name: string; description: string }[];
}

export interface UserOoOData {
  id: number;
  idUser: number;
  dateStartOoo: string;
  dateEndOoo: string;
  description: string;
  flgType: UserOoOType;
}

export enum UserOoOType {
  SickLeave = 'H', // Malattia
  Vacation = 'F' // Ferie
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.apiUrl + ApiPaths.Users;
  private roleApiUrl = environment.apiUrl + ApiPaths.Role;
  private userOoOApiUrl = environment.apiUrl + ApiPaths.UserOoO;
  private authService = inject(AuthService);
  private httpClient = inject(HttpClient);

  getAllUsers(): Observable<User[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.get<User[]>(`${this.apiUrl}/GetAll`, { headers });
  }
  getUserById(idUser: number): Observable<User> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.get<User>(`${this.apiUrl}/GetUserById`, {
      headers,
      params: { id: idUser }
    });
  }

  getUserByRoleId(idRole: number): Observable<User[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.get<User[]>(`${this.apiUrl}/GetUsersByRuolo`, {
      headers,
      params: { idRuolo: idRole }
    });
  }

  getUserByGeographicAreaID(idGeographicArea: number): Observable<User[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.get<User[]>(`${this.apiUrl}/GetUsersByIdAreaGeo`, {
      headers,
      params: { idAreaGeo: idGeographicArea }
    });
  }

  getUsersByRoleIds(roleIds: number[]): Observable<User[]> {
    const observables = roleIds.map((roleId) => this.getUserByRoleId(roleId));
    return forkJoin(observables).pipe(
      map((userArrays) => {
        return userArrays.reduce((acc, users) => acc.concat(users), []);
      })
    );
  }

  getAllRoles(): Observable<Role[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.get<Role[]>(`${this.roleApiUrl}/GetAll`, {
      headers
    });
  }

  insertUser(newUser: NewUser): Observable<NewUser> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.post<NewUser>(`${this.apiUrl}/InsertUser`, newUser, {
      headers
    });
  }

  updateUser(updatedUser: NewUser): Observable<NewUser> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.put<NewUser>(
      `${this.apiUrl}/UpdateUser`,
      updatedUser,
      {
        headers
      }
    );
  }

  insertOOOUser(
    idUser: number,
    dateStartOoo: string,
    dateEndOoo: string,
    description: string,
    flgType: string
  ): Observable<number> {
    const headers = this.authService.getHeadersWithAuthorization();
    const body = {
      idUser,
      dateStartOoo,
      dateEndOoo,
      description,
      flgType
    };

    return this.httpClient.post<number>(
      `${this.userOoOApiUrl}/InsertDatesOoO`,
      body,
      { headers }
    );
  }

  deleteOOOUser(idFerie: number): Observable<void> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.delete<void>(`${this.userOoOApiUrl}/${idFerie}`, {
      headers
    });
  }

  getOOOByIdUtente(idUser: number): Observable<UserOoOData[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    const apiUrl = `${this.userOoOApiUrl}/GetByIDUser/${idUser}`;
    return this.httpClient.get<UserOoOData[]>(apiUrl, { headers });
  }

  updateOOOUser(
    id: number,
    dateStart: string,
    dateEnd: string,
    description: string,
    flgType: UserOoOType
  ): Observable<void> {
    const headers = this.authService.getHeadersWithAuthorization();
    const body = {
      id,
      dateStart,
      dateEnd,
      description,
      flagtype: flgType
    };

    return this.httpClient.put<void>(
      `${this.userOoOApiUrl}/UpdateDates`,
      body,
      {
        headers
      }
    );
  }
}
