import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiPaths } from 'src/environments/enums/apiPaths.model';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

export interface Supplier {
  id: number;
  commercialName?: string;
  socialEntity?: string;
  pIva?: string;
  address?: string;
  name?: string;
  email?: string;
  telephone?: string;
}

export interface SupplierRequest {
  id: number;
  commercialName?: string;
  socialEntity?: string;
  pIva?: string;
  address?: string;
  name?: string;
  email?: string;
  telephone?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  private apiUrl = environment.apiUrl + ApiPaths.Supplier;
  private suppliersCache: Supplier[] | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getAllSuppliers(): Observable<Supplier[]> {
    if (this.suppliersCache) {
      return of(this.suppliersCache);
    }

    const headers = this.authService.getHeadersWithAuthorization();

    return this.http.get<Supplier[]>(this.apiUrl + '/GetAll', { headers }).pipe(
      map((suppliers) => {
        this.suppliersCache = suppliers.sort((a, b) => {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return nameA.localeCompare(nameB);
        });

        return this.suppliersCache;
      })
    );
  }

  getSupplierById(idSupplier: number): Observable<Supplier> {
    if (this.suppliersCache) {
      const supplier = this.suppliersCache.find((s) => s.id === idSupplier);
      if (supplier) {
        return of(supplier);
      }
    }

    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Content-Type', 'application/json');
    return this.http.get<Supplier>(`${this.apiUrl}/${idSupplier}`, { headers });
  }

  createSupplier(supplierData: SupplierRequest): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Content-Type', 'application/json');
    return this.http.post<any>(this.apiUrl, supplierData, { headers }).pipe(
      map((response) => {
        this.suppliersCache = null;
        return response;
      })
    );
  }

  editSupplier(supplierData: SupplierRequest): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', '*/*')
      .set('Content-Type', 'application/json');
    return this.http.put<any>(this.apiUrl, supplierData, { headers }).pipe(
      map((response) => {
        this.suppliersCache = null;
        return response;
      })
    );
  }
}
