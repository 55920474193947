import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPaths } from '../../../environments/enums/apiPaths.model';
import {
  Service,
  ServiceRequest,
  ServiceToArrivalRequest
} from '../model/service.model';
import { AuthService } from './auth.service';
import { UserAuthService } from './user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  private readonly httpClient = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserAuthService);

  private apiUrl = environment.apiUrl + ApiPaths.Service;
  private cache = new Map<string, Observable<Service[]>>();

  getAllServices(statusKai: number[]): Observable<Service[]> {
    const statusKaiQuery = statusKai.join(',');
    const cacheKey = `getAllServices_${statusKaiQuery}`;

    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey) as Observable<Service[]>;
    }

    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain');

    const request = this.httpClient
      .get<
        Service[]
      >(`${this.apiUrl}/GetAllServiceBystatusListKai?statusList=${statusKaiQuery}`, { headers })
      .pipe(
        catchError((error) => {
          console.error('Error fetching services:', error);
          return of([]);
        })
      );

    this.cache.set(cacheKey, request);
    return request;
  }

  getServiceById(serviceId: number): Observable<Service> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');
    return this.httpClient.get<Service>(`${this.apiUrl}/${serviceId}`, {
      headers
    });
  }

  deleteService(serviceId: number): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'application/json');
    return this.httpClient
      .delete(`${this.apiUrl}/${serviceId}`, { headers })
      .pipe(tap(() => this.invalidateCache()));
  }

  private cacheByIdArrival = new Map<string, Observable<Service[]>>();

  getAllServicesByIdArrival(idArrival: number): Observable<Service[]> {
    const apiUrl = `${this.apiUrl}/GetAllByIdArrival?idArrival=${idArrival}`;

    if (this.cacheByIdArrival.has(apiUrl)) {
      return this.cacheByIdArrival.get(apiUrl) as Observable<Service[]>;
    }

    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'application/json');

    const request = this.httpClient.get<Service[]>(apiUrl, { headers }).pipe(
      tap((servicesList) =>
        this.cacheByIdArrival.set(apiUrl, of(servicesList))
      ),
      catchError((error) => {
        console.error('Error fetching services:', error);
        return throwError(error);
      })
    );

    this.cacheByIdArrival.set(apiUrl, request);
    return request;
  }

  getServicesFromIdKai(idKai: number): Observable<Service[]> {
    const headers = this.authService.getHeadersWithAuthorization();
    return this.httpClient.get<Service[]>(
      `${this.apiUrl}/GetAllByIdKai?idkai=${idKai}`,
      { headers }
    );
  }

  createService(
    serviceData: Partial<ServiceToArrivalRequest>
  ): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain')
      .set('Content-Type', 'application/json');
    return this.httpClient
      .post<any>(
        `${this.apiUrl}/InsertServiceToArrival`,
        { ...serviceData, idUser: this.userService.getUserId() },
        { headers }
      )
      .pipe(tap(() => this.invalidateCache()));
  }

  updateService(serviceData: ServiceRequest): Observable<any> {
    // Remove serviceCategory and extInvoices from the payload otherwise we have an error
    // Remove chargeWithVat and margin to trigger again the count
    const {
      serviceCategory,
      extInvoices,
      chargeWithVat,
      margin,
      documentCounter,
      ...payload
    } = serviceData;

    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain')
      .set('Content-Type', 'application/json');
    return this.httpClient
      .put<any>(
        this.apiUrl,
        { ...payload, chargeWithVat: 0, margin: 0 },
        { headers }
      )
      .pipe(tap(() => this.invalidateCache()));
  }

  async getServiceProgress(service: Service): Promise<number> {
    /**
     * Fattura (ha peso per solo supplier) 20 (servizio internal già 20)
     *  - una per ogni costo, 20 solo se ci sono tutte
     * Charge 20
     * Status 20 (=== 'Delivered')
     * Cost (ha peso per solo supplier) 20 legato ad una invoice (servizio internal già 20)
     * inserita i your b 20
     */
    let percentage = 0;
    if (!service.internal && service.extInvoices?.length) {
      service.extInvoices.forEach((extInv) => {
        if (extInv.costWithoutVat) {
          percentage += 20;
        }
      });
      percentage += service.documentCounter * 20;
      percentage /= service.extInvoices.length;
    } else {
      percentage += 40;
    }
    percentage += service.chargeWithoutVat ? 20 : 0;
    percentage += service.status === 1 ? 20 : 0;
    percentage += service.bwaready ? 20 : 0;
    return Math.floor(percentage);
  }

  moveToAnotherArrival(idService: number, idArrival: number): Observable<any> {
    const headers = this.authService
      .getHeadersWithAuthorization()
      .set('Accept', 'text/plain');
    return this.httpClient
      .put<any>(
        `${this.apiUrl}/ChangeRelationArrivalService?idService=${idService}&idArrival=${idArrival}`,
        null,
        { headers: headers }
      )
      .pipe(tap(() => this.invalidateCache()));
  }

  updateBwaStatusesService(
    idService: number,
    bReady?: boolean,
    bwaDone?: boolean
  ): Observable<any> {
    const headers = this.authService.getHeadersWithAuthorization();
    let url = `${this.apiUrl}/UpdateBreadyBwaDone?idService=${idService}`;
    if (bReady !== undefined && bReady !== null) {
      url += `&bReady=${bReady}`;
    }
    if (bwaDone !== undefined && bwaDone !== null) {
      url += `&bwaDone=${bwaDone}`;
    }
    return this.httpClient
      .put(url, null, { headers, responseType: 'text' })
      .pipe(tap(() => this.invalidateCache()));
  }

  private invalidateCache(): void {
    this.cache.clear();
    this.cacheByIdArrival.clear();
  }
}
