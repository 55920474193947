<form class="form-inline search-full" [class.open]="navServices.search">
  <div class="form-group w-100 mb-0 item-search">
    <input
      class="form-control-plaintext"
      [(ngModel)]="text"
      [ngModelOptions]="{ standalone: true }"
      (keyup)="searchTerm(text)"
      type="text"
      autocomplete="off"
      placeholder="Search.."
    />
    <svg
      class="close-search"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      (click)="clearSearch()"
    >
      <line
        x1="18"
        y1="6"
        x2="6"
        y2="18"
        stroke="black"
        stroke-width="2"
      ></line>
      <line
        x1="6"
        y1="6"
        x2="18"
        y2="18"
        stroke="black"
        stroke-width="2"
      ></line>
    </svg>

    @if (menuItems?.length) {
      <div
        class="Typeahead-menu mt-3"
        [class.is-open]="searchResult"
        id="search-outer"
      >
        <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems">
          <div class="ProfileCard-avatar">
            <app-svg-icon
              [icon]="menuItem.icon"
              class="header-search"
            ></app-svg-icon>
          </div>
          <div class="ProfileCard-details">
            <div class="ProfileCard-realName">
              <a
                [routerLink]="menuItem?.path"
                class="realname"
                (click)="removeFix()"
                >{{ menuItem?.title }}</a
              >
            </div>
          </div>
        </div>
      </div>
    }
    <div class="Typeahead-menu mt-3" [class.is-open]="searchResultEmpty">
      <div class="tt-dataset tt-dataset-0">
        <div class="EmptyMessage">There are no result found.</div>
      </div>
    </div>
  </div>
</form>
